<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
				</p>
				<div class="h-b">
					<el-button type="primary" size="small" @click="add" v-right-code="'District:Edit'">新增</el-button>
				</div>
				<div class="h-b">
					<el-button type="text" size="small" @click="save" v-right-code="'District:Edit'">保存</el-button>
				</div>
			</div>
		</div>

		<div class="form-list">
			<el-form :model="dataSource" ref="_sysConfigForm">
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color: red">*</span> 区划名称：</el-col>
					<el-col :span="7">
						<el-form-item prop="DisName">
							<el-input v-model="dataSource.DisName" :disabled="isDisabled" :minlength="2"
								placeholder="区划名称"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color: red">*</span> 区划编码：</el-col>
					<el-col :span="7">
						<el-form-item prop="PostCode">
							<el-input v-model="dataSource.DisCode" placeholder="区划编码"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color: red">*</span> 区划类型：</el-col>
					<el-col :span="7">
						<el-select v-model="dataSource.DisType" placeholder="请选择" @change="changeType">
							<el-option v-for="item in areaTypeList" :key="item.code" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color: red">*</span> 区划类别：</el-col>
					<el-col :span="7">
						<el-select v-model="dataSource.RegionType" placeholder="请选择">
							<el-option v-for="item in dataSource.RegionTypeList" :key="item.Key" :label="item.Value"
								:value="item.Key">
							</el-option>
						</el-select>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color: red"></span> 邮政编码：</el-col>
					<el-col :span="7">
						<el-form-item prop="PostCode">
							<el-input v-model="dataSource.PostCode" placeholder="邮政编码"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title"><span style="color: red"></span> 排序等级：</el-col>
					<el-col :span="7">
						<el-form-item prop="Sort">
							<el-input v-model="dataSource.Sort" placeholder="排序等级"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-col :span="3" class="form-title"><span style="color: red"></span> 父级区划：</el-col>
					<el-col :span="7">
						<el-form-item prop="DisPCode">
							<el-select v-model="dataSource.DisPCode" placeholder="请选择" filterable>
								<el-option v-for="item in dataSource.AddressList" :key="item.Key" :label="item.Value"
									:value="item.Key">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isDisabled: false,
				areaTypeList: [{
						code: 1,
						name: "省份"

					},
					{
						code: 2,
						name: "城市"

					},
					{
						code: 3,
						name: "地区"

					}
				],
				addressList: []
			}
		},
		props: {
			config: {
				isDetailDisplay: false,
			},
			dataSource: {},
		},
		mounted() {
			this.Event.$on('clearEditSysConfigForm', () => this.resetForm)
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					this.isDisabled = this.Utils.isGreaterThanZero(
						this.dataSource.ConfigId
					)
				},
				deep: true,
			},
		},
		methods: {
			add() {
				this.Event.$emit('onAdd')
			},
			resetForm() {
				!this.isPassValidate && this.$refs['_sysConfigForm'].resetFields() //清空表单
			},
			changeType() {
				if (this.dataSource.DisType == 2) {
					// if (!this.dataSource.DisCode) {
					// 	this.Utils.messageBox('选择城市，请填写城市编码.', 'error')
					// 	return
					// }
					this.$ajax.send("omsapi/district/getadresslist?type=" + 1, "get", {}, (
						result) => {
						this.dataSource.DisPCode = null;
						this.dataSource.AddressList = result.Result;
					});

				} else if (this.dataSource.DisType == 3) {
					// if (!this.dataSource.DisCode) {
					// 	this.Utils.messageBox('选择城市，请填写地区编码.', 'error')
					// 	return
					// }
					this.$ajax.send("omsapi/district/getadresslist?type=" + 2, "get", {}, (result) => {

						this.dataSource.DisPCode = null;
						this.dataSource.AddressList = result.Result;
					});

				} else {
					this.dataSource.DisPCode = null;
					this.dataSource.AddressList = [];
				}

			},
			save() {
				var _this = this;

				var url = "omsapi/district/create";
				if (this.dataSource.Id) {
					url = "omsapi/district/edit";
				}

				_this.$ajax.send(
					url,
					'post',
					_this.dataSource,
					(data) => {
						_this.dataSource.Id = data.Result;
						_this.$parent.syncDataSource()
						_this.Event.$emit('reloadPageList', data.Result)
						_this.Utils.messageBox('保存成功.', 'success')
					}
				)

			},
		},
	}
</script>